import React from 'react'

const GaleriasPage = () => {
    return (
        <div>
            <h1>Pagina Galerias</h1>
        </div>
    )
}

export default GaleriasPage

import React from 'react';
import { Jumbotron, Button, Row, Col } from 'react-bootstrap';
import frente_ites from '../img/frente_ites.jpg';
import {Link} from "react-router-dom";


const Promo = () => {
    return (
        <div style={{backgroundColor: '#e2eafc', padding: 30, marginBottom: 30, marginTop: 30}}>
            <Jumbotron fluid>
                
                <Row>
                    <Col sm={12} md={7}>
                        
                            <h1 style={{color: '#0d6efd'}}>Bienvenidos a ITES!</h1>
                            <p>Te invitamos a ser parte del Instituto Tecnológico de Educación Superior (ITES), y que puedas conocer nuestras Ofertas Académicas Gratuitas de nivel terciario (superior no universitario), con validez nacional y amplia salida laboral.              
                            <h5 style={{color: '#0d6efd'}}>¡Donde tu Futuro te Espera!</h5>
                            <p style={{marginTop: 30}}>
                                <Link to="/institucional" className="btn btn-outline-primary">Conocer más...</Link>
                            </p>
                        </p>
                    </Col>
                    <Col sm={12} md={5}>
                        <img className="img-fluid" src={frente_ites} alt="foto frente ites santa rosa" />
                    </Col>
                </Row>               
                
                
            </Jumbotron>
        </div>
    )
}

export default Promo

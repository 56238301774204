import React from 'react'
import Encabezado from '../components/Encabezado'

const ConsultasPage = () => {
    return (
        <>
          <Encabezado titulo={"Consultas"} />
        </>
    )
}

export default ConsultasPage

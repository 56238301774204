import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col, Table } from 'react-bootstrap';

const CarreraContable = () => {
    return (
        <div>
            
            <Encabezado titulo={"TÉCNICO SUPERIOR EN ADMINISTRACIÓN Y GESTIÓN CONTABLE - Sede Santa Rosa"} />

            <h4 className="text-center">Plan de Estudios: (RESOLUCION PROVINCIAL Nº 271/22) – PLAN 2022</h4>


            <Table striped bordered size="sm">
                <thead>
                    <tr>
                    <th>Código</th>
                    <th>Correlativa</th>
                    <th>Materia</th>
                    <th>H.R.</th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center h6">- PRIMER AÑO -</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>-</td>
                        <td>MATEMÁTICA	</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>02</td>
                        <td>-</td>
                        <td>DERECHO I: CIVIL </td>
                        <td>-</td>
                        
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>-</td>
                        <td>INGLÉS	</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>04</td>
                        <td>-</td>
                        <td>ECONOMIA </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>-</td>
                        <td>CONTABILIDAD I</td>
                        <td></td>                        
                    </tr>
                    <tr>
                        <td>06</td>
                        <td>-</td>
                        <td>SOCIOLOGIA DE LAS ORGANIZACIONES </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>07</td>
                        <td>-</td>
                        <td>PRINCIPIOS DE ADMINISTRACIÓN </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>08</td>
                        <td>-</td>
                        <td>GESTIÓN ADMINISTRATIVA CONTABLE </td>
                        <td>-</td>                        
                    </tr>                                     
                    
                    <tr>
                        <td colSpan="4" className="text-center h6">- SEGUNDO AÑO -</td>
                    </tr>
                    <tr>                        
                        <td>09</td>
                        <td>--</td>
                        <td>ESTADISTICA	</td>
                        <td>-</td>                        
                    </tr>
                    <tr>                        
                        <td>10</td>
                        <td>--</td>
                        <td>INFORMÁTICA I: OFIMÁTICA </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>5</td>
                        <td>CONTABILIDAD II</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>1</td>
                        <td>CALCULO FINANCIERO</td>
                        <td>-</td>                        
                    </tr>        
                    <tr>
                        <td>13</td>
                        <td>2</td>
                        <td>DERECHO II: LABORAL</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>2</td>
                        <td>DERECHO II: COMERCIAL</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>5</td>
                        <td>IMPUESTOS I</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>8</td>
                        <td>GESTION ADMINISTRATIVA CONTABLE II </td>
                        <td>-</td>                        
                    </tr>                              
                    <tr>
                        <td colSpan="4" className="text-center h6">- TERCER AÑO -</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>15</td>
                        <td>IMPUESTOS II </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>11</td>
                        <td>CONTABILIDAD III </td>
                        <td>-</td>                        
                    </tr>  
                    <tr>
                        <td>19</td>
                        <td>11</td>
                        <td>COSTOS Y PRESUPUESTOS</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>5-12</td>
                        <td>ADMINISTRACION FINANCIERA</td>
                        <td>-</td>                        
                    </tr>      
                    <tr>
                        <td>21</td>
                        <td>7</td>
                        <td>ADMINISTRACION ESTRATÉGICA </td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>10</td>
                        <td>INFORMÁTICA II: SISTEMAS DE INFORMACIÓN PARA LA GESTIÓN</td>
                        <td>-</td>                        
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>16</td>
                        <td>GESTIÓN ADMINISTRATIVA CONTABLE III</td>
                        <td>-</td>                        
                    </tr>
                    
                </tbody>
            </Table>

            <h5>Total Carga Horaria: 2304 HORAS CÁTEDRA (1536 HORAS RELOJ)</h5>

        </div>
    )
}

export default CarreraContable

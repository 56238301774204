import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Encabezado from '../components/Encabezado';
import sede_santa_rosa from '../img/frente_ites.jpg'
import sede_toay from '../img/sede_toay.jpg';

const PoliticaPrivacidadPage = () => {
    return (
        <div>
            <Encabezado titulo={"Política de Privacidad de la Información de Usuarios"} />
            <section>                
                    <Row>                        
                        <Col sm={12}>
                            <h4>Política de privacidad para aplicaciones móviles</h4>
                            <p>
                            Esta política de privacidad establece el uso de la aplicación móvil “ITES Asistencia”, creada y mantenida por Instituto Tecnológico de Educación Superior (ITES) para plataformas Google Android.
                            </p>        

                            <h4>¿Qué información obtiene el aplicativo y como es utilizada?</h4>    

                            <b>Información provista por el usuario</b> 
                            <p>La app no requiere ningún tipo de registro previo y unicamente son requeridas credenciales en uso por el/la docente en sistema de autogestión del Instituto Tecnológico de Educación Superior.</p>
 
                            <b>Información obtenida automáticamente</b>
                            <p>Adicionalmente, la app puede recopilar datos sobre asistencia de alumnos a clases a las cuales se haya inscripto para el ciclo educativo vigente.</p>   

                            <b> ¿La app obtiene la información precisa y en tiempo real del dispositivo?</b>

                            <p> La app no recopila información precisa y en tiempo real de la ubicación del dispositivo en ningún momento.</p>

                            <b> ¿La app obtiene la información precisa y en tiempo real del dispositivo?</b>

                            <p> La app no recopila información precisa y en tiempo real de la ubicación del dispositivo en ningún momento.</p>

                            <b> ¿Pueden terceros obtener acceso a la información obtenida por la app?</b>  

                            <p>Si. Podemos compartir la información proporcionada por el usuario o la obtenida automáticamente en los siguientes casos:
                            
                            <ul>
                                <li>Cuando sea requerido por Autoridades Educativas para fines de organización de la institución a efectos de proceso de mejora interna.</li>
                            </ul>
                            </p>

                            <b> Política de retención y manejo de información</b>

                            <p>Conservaremos los datos brindados por el usuario y los recolectados automáticamente de forma indefinida, ya que se necesita para el uso correcto de la app.</p>                  
                            
                            <b> Seguridad</b>

                            <p> Nos preocupamos por salvaguardar la confidencialidad de su información, por lo que
 contamos con las medidas necesarias para lograr este fin. Por ejemplo, limitamos el acceso a esta información a los empleados y contratistas autorizados que necesitan
 conocer dicha información para operar, desarrollar o mejorar nuestra app. Tenga en cuenta que, aunque nos esforzamos por proveer un nivel de seguridad razonable para
 manejar y procesar la información,no existe ningún sistema que pueda prevenir todas las brechas de seguridad potenciales.</p>

                            <b>Cambios</b>

                            <p>Esta Política de Privacidad puede ser actualizada de vez en cuando por cualquier razón.
 Le notificaremos de cualquier cambio en la misma, mediante la publicación de la nueva política de privacidad en esta página.</p>

                            <b>Su consentimiento</b>

                            <p>Al utilizar los Servicios, usted está consintiendo al procesamiento de los datos de usuario proporcionados tal como se establece en esta Política de Privacidad.</p>

                            <br></br>

                            <h4 className='text-center'>
                                Instituto Tecnológico de Educación Superior
                            </h4>
                        </Col>
                    </Row>     
            </section>
        </div>

    )
}

export default PoliticaPrivacidadPage

import React from 'react';
// import avatar from '../img/avatar_ites.PNG';
// import lorem from '../img/noticias/lorem.png';
import { Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const Noticia = props => {
    const { noticia } = props;

    // console.log(noticia);
    const foto = require('../img/noticias/' + noticia.imagen).default;    

    return (
        <article className="blog-post">
            <Row>

                <h5 style={{fontSize: 20, color:'#1d3557'}}>{noticia.titulo}</h5>
                
                <p style={{fontSize: 13, color:'#0d6efd'}}>
                    <FontAwesomeIcon icon={faCalendar} color='#b2b2b2' />
                    &nbsp;{noticia.fecha} {noticia.destacado && <Badge style={{backgroundColor:'#0d6efd'}}>DESTACADO</Badge>}
                </p>
                <Col sm={12} md={3}>                        
                    <img className="img-thumbnail" src={foto} alt="imagen noticia"/>            
                </Col>
                <Col sm={12} md={9}>
                    <p dangerouslySetInnerHTML={{ __html: noticia.cuerpo }}></p>            
                </Col>
                
            </Row>
            
            
            <hr/>
        </article>
    )
}

export default Noticia

import React from 'react';
import Carrousel from '../components/Carrousel';
import Contenido from '../components/Contenido';
import Promo from '../components/Promo';

const HomePage = () => {
    return (
        <>
            <Carrousel />
            <Promo />
            <Contenido />  
        </>
    )
}

export default HomePage

import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col, Table } from 'react-bootstrap';

const CarreraDesarrollo = () => {
    return (
        <div>
            
            <Encabezado titulo={"TÉCNICO SUPERIOR EN DESARROLLO DE SOFTWARE - Sede Santa Rosa"} />

            <h4 className="text-center">Plan de Estudios: (RESOLUCIÓN MINISTERIAL Nº 1892/14) – PLAN 2015</h4>


            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Correlativas</th>
                        <th>Materia</th>
                        <th>H.C.</th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center h6">- PRIMER AÑO -</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>-</td>
                        <td>ANALISIS MATEMÁTICO</td>
                        <td>5</td>
                        
                    </tr>
                    <tr>
                        <td>02</td>
                        <td>-</td>
                        <td>INGLES</td>
                        <td>2</td>
                        
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>-</td>
                        <td>ELECTRONICA ANALOGICA Y DIGITAL	  </td>
                        <td>3</td>
                        
                    </tr>
                    <tr>
                        <td>04</td>
                        <td>-</td>
                        <td>PROGRAMACION I</td>
                        <td>4</td>                        
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>-</td>
                        <td>ESTRUCTURA DE DATOS Y ALGORITMOS  </td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>06</td>
                        <td>-</td>
                        <td>INGENIERIA DE REQUERIMIENTOS</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>07</td>
                        <td>-</td>
                        <td>FUNDAMENTOS DE ECONOMIA Y CONTABILIDAD	</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>08</td>
                        <td>-</td>
                        <td>FISICA BÁSICA	</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>09</td>
                        <td>-</td>
                        <td>ALGEBRA</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>-</td>
                        <td>PRÁCTICA PROFESIONALIZANTE-SOFTWARE FACTORY I</td>
                        <td>3</td>                        
                    </tr>
                    
                    <tr>
                        <td colSpan="4" className="text-center h6">- SEGUNDO AÑO -</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>1 - 3 - 5 - 8 - 9</td>
                        <td>MICROCONTROLADORES I</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>5 - 6</td>
                        <td>GESTION Y ADMINISTRACIÓN DE BASES DE DATOS</td>
                        <td>4</td>                        
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>4 - 5 - 6</td>
                        <td>MODELADO DE SISTEMAS</td>
                        <td>2</td>                        
                    </tr>      
                    <tr>
                        <td>14</td>
                        <td>4 - 5</td>
                        <td>PROGRAMACION II - ORIENTACION A OBJETOS</td>
                        <td>4</td>                        
                    </tr>           
                    <tr>
                        <td>15</td>
                        <td>6</td>
                        <td>IDENTIDAD VISUAL</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>16</td>
                        <td>1</td>
                        <td>INVESTIGACIÓN OPERATIVA</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>17</td>
                        <td>1 - 5 - 9</td>
                        <td>INTRODUCCION A LA MINERIA DE DATOS	</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>18</td>
                        <td>5</td>
                        <td>ANALISIS INTELIGENTE DE DATOS</td>
                        <td>2</td>                        
                    </tr>   
                    <tr>
                        <td>19</td>
                        <td>1</td>
                        <td>PROBABILIDAD Y ESTADISTICA	</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>20</td>
                        <td>-</td>
                        <td>TELEINFORMATICA Y REDES</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>21</td>
                        <td>10</td>
                        <td>PRÁCTICA PROFESIONALIZANTE-SOFTWARE FACTORY I</td>
                        <td>8</td>                        
                    </tr>  
                    <tr>
                        <td colSpan="4" className="text-center h6">- TERCER AÑO -</td>
                    </tr>

                    <tr>
                        <td>22</td>
                        <td>12 - 13 - 14</td>
                        <td>PROGRAMACION III - APLICACIONES MÓVILES</td>
                        <td>4</td>                        
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>11 - 20</td>
                        <td>ROBOTICA Y AUTOMATIZACION INDUSTRIAL</td>
                        <td>4</td>                        
                    </tr>   
                    <tr>
                        <td>24</td>
                        <td>11</td>
                        <td>MICROCONTROLADORES II</td>
                        <td>5</td>                        
                    </tr>  
                    <tr>
                        <td>25</td>
                        <td>7</td>
                        <td>ORGANIZACIÓN EMPRESARIAL COMPUTARIZADA</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>26</td>
                        <td>12 - 13 - 14</td>
                        <td>PROGRAMACION III - APLICACIONES WEB</td>
                        <td>4</td>                        
                    </tr>  
                    <tr>
                        <td>27</td>
                        <td>13 - 14</td>
                        <td>GESTION DE PROYECTOS DE SOFTWARE</td>
                        <td>2</td>                        
                    </tr>  
                    <tr>
                        <td>28</td>
                        <td>17 - 18</td>
                        <td>INTRODUCCION AL DATA WAREHOUSE</td>
                        <td>3</td>                        
                    </tr>  
                    <tr>
                        <td>29</td>
                        <td>17 - 18</td>
                        <td>EXPLOTACIÓN DE DATOS Y DESCUBRIMIENTO DE CONOCIMIENTO</td>
                        <td>3</td>                        
                    </tr>  
                    <tr>
                        <td>30</td>
                        <td>21</td>
                        <td>PRÁCTICA PROFESIONALIZANTE-SOFTWARE FACTORY III</td>
                        <td>10</td>                        
                    </tr>  
                </tbody>
            </Table>

            <h5>Total Carga Horaria: 3200 HORAS CÁTEDRA (2133 HORAS RELOJ)</h5>

        </div>
    )
}

export default CarreraDesarrollo

import React from 'react';
// import { Container } from 'react-bootstrap';
import logo_me from '../img/logo_me_web.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';


const Footer = () => {
    function obtenerAnio () {
        const fecha = new Date();        
        const anioActual = fecha.getFullYear(); 

        return anioActual;
    }

    return (

        <div style={{marginTop: 30}}>
            <div className="ubicacion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3>&nbsp;Enlaces</h3>
                            <ul>
                                <a href="#"><li>Inicio</li></a>
                                <a href="#"><li>Institucional</li></a>                                
                                <a href="#"><li>Carreras</li></a>                                
                                <a href="https://es-la.facebook.com/ITESSR/" target="blank" rel="noopener noreferrer"><li>Facebook</li></a>
                                <a href="https://www.youtube.com/channel/UC6NhvKdqIHgzjSf-5MM9tlQ" target="blank" rel="noopener noreferrer"><li>Youtube</li></a>
                                <a href="mailto://ites.santarosa@lapampa.edu.ar" rel="noopener noreferrer"><li>Email</li></a>

                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h3><a name="sedes"></a>&nbsp;Sedes</h3>

                            <p>INSTITUTO TECNOLOGICO DE EDUCACION SUPERIOR</p>
                            <p><FontAwesomeIcon icon={faUniversity} /><strong> Sede Santa Rosa</strong></p>
                            <p>Domicilio: Pico y Sarmiento - Santa Rosa - La Pampa</p>
                            <p>Teléfono: (02954) 700637</p>
                            <p>Email: ites.santarosa@lapampa.edu.ar</p>
                            <p><FontAwesomeIcon icon={faUniversity} /><strong> Extensión Aúlica Toay</strong></p>
                            <p>Domicilio: Avenida Reg. 13 de Caballeria 1373 - Toay - La Pampa</p>
                            <p>Teléfono: (02954) 492441</p>
                            <p><FontAwesomeIcon icon={faUniversity} /><strong> Extensión Aúlica Macachín</strong></p>
                            <p>Domicilio: C. Milstein 157 - Macachin - La Pampa</p>
                            <p>Teléfono: (02953) 452969</p>
                            <p><FontAwesomeIcon icon={faUniversity} /><strong> Extensión Aúlica Santa Isabel</strong></p>
                            <p>Domicilio: Sarmiento 126 - Santa Isabel - La Pampa</p>
                            <p>Teléfono: (02338) 493161</p>

                        </div>
                        <div className="col-md-4">
                            <a href="https://sitio.lapampa.edu.ar/" target="_blank"><img src={logo_me} width="300px" className="img-responsive" alt="Ministerio Educación Provincia de La Pampa" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <p className="text-center">&copy; 2018 - {obtenerAnio()} - <strong>ITES</strong> | Instituto Tecnológico de Educación Superior</p>
            </footer>
        </div>

    )
}

export default Footer

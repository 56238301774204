import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col, Table } from 'react-bootstrap';

const CarreraAdministracion = () => {
    return (
        <div>
            
            <Encabezado titulo={"TÉCNICO SUPERIOR EN ADMINISTRACIÓN Y GESTIÓN DE EMPRESAS - Sede Santa Rosa"} />

            <h4 className="text-center">Plan de Estudios: (DECRETO PROVINCIAL Nº 1071/12) – PLAN 2013</h4>


            <Table striped bordered size="sm">
                <thead>
                    <tr>
                    <th>Código</th>
                    <th>Correlativa</th>
                    <th>Materia</th>
                    <th>H.R.</th>                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center h6">- PRIMER AÑO -</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>-</td>
                        <td>COMUNICACIÓN ORAL Y ESCRITA	</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>02</td>
                        <td>-</td>
                        <td>FUNDAMENTOS DEL ANÁLISIS ECONOMICO	</td>
                        <td>3</td>
                        
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>-</td>
                        <td>FUNDAMENTOS DE CONTABILIDAD	</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>04</td>
                        <td>-</td>
                        <td>ECONOMIA GENERAL</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>-</td>
                        <td>INGLES I</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>06</td>
                        <td>-</td>
                        <td>FILOSOFIA, ESTADO Y EMPRESA</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>07</td>
                        <td>-</td>
                        <td>MATEMATICA	</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>08</td>
                        <td>-</td>
                        <td>DERECHO CIVIL Y COMERCIAL</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>09</td>
                        <td>-</td>
                        <td>SISTEMAS Y PROCESAMIENTO DE DATOS	</td>
                        <td>2</td>                        
                    </tr>                    
                    
                    <tr>
                        <td colSpan="4" className="text-center h6">- SEGUNDO AÑO -</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>2 - 4</td>
                        <td>ECONOMIA POLÍTICA ARGENTINA	</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>7</td>
                        <td>CALCULO FINANCIERO</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>-</td>
                        <td>ORGANIZACIÓN, LIDERAZGO Y MANAGEMENT</td>
                        <td>2</td>                        
                    </tr>        
                    <tr>
                        <td>13</td>
                        <td>1 - 5</td>
                        <td>INGLES II</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>6</td>
                        <td>SOCIOLOGIA</td>
                        <td>3</td>                        
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>3 - 7</td>
                        <td>CONTABILIDAD ADMINISTRATIVA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>8</td>
                        <td>LEGISLACION LABORAL Y SEGUROS</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>8</td>
                        <td>SOCIEDADES, CONVOCATORIA Y QUIEBRAS	</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>-</td>
                        <td>EMPRESA SIMULADA I</td>
                        <td>6</td>                        
                    </tr>            
                    <tr>
                        <td colSpan="4" className="text-center h6">- TERCER AÑO -</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>11</td>
                        <td>ESTADISTICA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>10</td>
                        <td>PLANEAMIENTO, COMERCIALIZACION Y MARKETING</td>
                        <td>2</td>                        
                    </tr>      
                    <tr>
                        <td>21</td>
                        <td>13</td>
                        <td>INGLES – TÉCNICO COMERCIAL</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>16</td>
                        <td>LEGISLACION IMPOSITIVA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>11 – 12 – 15</td>
                        <td>ADMINISTRACION FINANCIERA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>15</td>
                        <td>LOGISTICA Y COSTO DE LA PRODUCCIÓN</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>11 – 12 – 16 – 17</td>
                        <td>GESTION DE PROYECTOS E INVERSIONES	</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>18</td>
                        <td>EMPRESA SIMULADA II</td>
                        <td>9</td>                        
                    </tr>                    

                    
                </tbody>
            </Table>

            <h5>Total Carga Horaria: 2448 HORAS CÁTEDRA (1632 HORAS RELOJ)</h5>

        </div>
    )
}

export default CarreraAdministracion

import React, {useEffect, useState} from 'react';
import dataCarreras from '../data/carreras.json';
import dataNoticias from '../data/noticias.json';
import { FormularioContacto } from './FormularioContacto';
import Noticia from './Noticia';
import OfertaEducativa from './OfertaEducativa';
import Paginacion from './Paginacion';
// import datos from '../services/noticias';


const Contenido = () => {    

    // const datosIniciales = noticias.GetAll();    

    const [carreras, setCarreras] = useState(dataCarreras);
    const [noticias, setNoticias] = useState(dataNoticias);   
    //const [noticias, setNoticias] = useState(data);    

    return (
        <>
            <div className="row g-5">
                <div className="col-md-8">                    

                    <h4 className="mt-10" style={{color: '#0d6efd', marginBottom: 30}}>ITES | Noticias </h4>

                    { noticias.map( item => <Noticia noticia={item} /> )}                    

                    {/* <Paginacion /> */}

                </div>

                <div className="col-md-4">
                    <div className="position-sticky" style={{top: '2rem'}}>
                        <div className="p-4 mb-3 bg-light rounded">
                            <h4 style={{color: '#0d6efd', marginBottom: 30}}>ITES | Oferta Académica</h4>
                            {carreras.map(item => (                                
                                <OfertaEducativa carrera={item}/>
                            ))}
                        </div>
                        
                    </div>
                </div>
              
            </div>
        </>
    )
};

export default Contenido;

import React from 'react';
import {Container} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import CarrerasPage from '../pages/CarrerasPage';
import GaleriasPage from '../pages/GaleriasPage';
import HomePage from '../pages/HomePage';
import InstitucionalPage from '../pages/InstitucionalPage';
import BarraSuperior from '../components/BarraSuperior';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import ConsultasPage from '../pages/ConsultasPage';
import CarreraDesarrollo from '../pages/CarreraDesarrollo';
import CarreraBromatologia from '../pages/CarreraBromatologia';
import CarreraEnergias from '../pages/CarreraEnergias';
import CarreraAdministracion from '../pages/CarreraAdministracion';
import CarreraContable from '../pages/CarreraContable';
import PoliticaPrivacidadPage from '../pages/PoliticaPrivacidadPage';

const AppRouter = () => {
    return (
        <Router>
            <Menu />
            <BarraSuperior />
            <Container>      
            <Switch>
                <Route exact path="/" component={HomePage} />                    
                <Route path="/institucional" component={InstitucionalPage} />                    
                <Route path="/galeria" component={GaleriasPage} />
                <Route path="/carreras" component={CarrerasPage} />
                <Route path="/consultas" component={ConsultasPage} />
                <Route path="/carrera_desarrollo" component={CarreraDesarrollo} />
                <Route path="/carrera_bromatologia" component={CarreraBromatologia} />
                <Route path="/carrera_energias" component={CarreraEnergias} />    
                <Route path="/carrera_administracion" component={CarreraAdministracion} />    
                <Route path="/carrera_contable" component={CarreraContable} />    
                <Route path="/politica_privacidad" component={PoliticaPrivacidadPage} />  
            </Switch>
            </Container>    
    <Footer/>
        </Router>
    )
}

export default AppRouter

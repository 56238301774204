import React from 'react';
import {Navbar, Container, Nav} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';


const Menu = () => {
    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor:'#042144', color: '#FFF', height: 40}} variant="dark">
            <Container>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">                    
                        
                    </Nav>
                    <Nav>                        
                        <Nav.Link href="https://es-la.facebook.com/ITESSR/" target="blank" rel="noopener noreferrer">Facebook</Nav.Link>
                        <Nav.Link href="https://instagram.com/ites.santarosa/" target="blank" rel="noopener noreferrer">Instagram</Nav.Link>
                        <Nav.Link href="https://www.youtube.com/channel/UC6NhvKdqIHgzjSf-5MM9tlQ" target="blank" rel="noopener noreferrer">Youtube</Nav.Link>
                        <Nav.Link href="mailto://ites.santarosa@lapampa.edu.ar">Email</Nav.Link>
                        {/* <Nav.Link href="#deets">Usuario</Nav.Link> */}
                        <Navbar.Text>
                            <FontAwesomeIcon icon={faPhone} />
                            <span> Teléfono: 02954-700637</span>
                        </Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Menu;

import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col, Table } from 'react-bootstrap';

const CarreraEnergias = () => {
    return (
        <div>
            
            <Encabezado titulo={"TÉCNICO SUPERIOR EN GESTIÓN DE ENERGÍAS RENOVABLES - Sede Toay"} />

            <h4 className="text-center">Plan de Estudios: (RES. MINISTERIAL N° 0122/21) – PLAN 2021</h4>


            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Correlativa</th>
                        <th>Materia</th>
                        <th>H.R.</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center h6">- PRIMER AÑO -</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>-</td>
                        <td>FÍSICA APLICADA</td>
                        <td>2</td>
                        
                    </tr>
                    <tr>
                        <td>02</td>
                        <td>-</td>
                        <td>MATEMÁTICA APLICADA</td>
                        <td>2</td>
                        
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>-</td>
                        <td>COMUNICACIÓN, TECNOLOGÍA E INNOVACIÓN</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>04</td>
                        <td>-</td>
                        <td>QUÍMICA APLICADA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>-</td>
                        <td>INGLÉS TÉCNICO</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>06</td>
                        <td>-</td>
                        <td>APROVECHAMIENTO DE LOS RECURSOS NATURALES</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>07</td>
                        <td>-</td>
                        <td>INTRODUCCIÓN A LAS ENERGÍAS RENOVABLES Y EFICIENCIA ENERGÉTICA</td>
                        <td>2:40</td>                        
                    </tr>
                    <tr>
                        <td>08</td>
                        <td>-</td>
                        <td>PRÁCTICA PROFESIONAL I</td>
                        <td>3:20</td>                        
                    </tr>                    
                    
                    <tr>
                        <td colSpan="4" className="text-center h6">- SEGUNDO AÑO -</td>
                    </tr>
                    <tr>
                        <td>09</td>
                        <td>1 - 2 - 6</td>
                        <td>SISTEMAS ELÉCTRICOS	</td>
                        <td>2:40</td>                        
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>1 - 2 - 6</td>
                        <td>SISTEMAS TÉRMICOS Y FLUIDOS</td>
                        <td>2:40</td>                        
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>1 - 2 - 6 - 7</td>
                        <td>ENERGÍA SOLAR – BIOMASA	</td>
                        <td>3:20</td>                        
                    </tr>            
                    <tr>
                        <td>12</td>
                        <td>1 - 2 - 6 - 7</td>
                        <td>ENERGÍA EÓLICA – HIDRÁULICA</td>
                        <td>3:20</td>                        
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>-</td>
                        <td>ELECTROTÉCNICA</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>8</td>
                        <td>PRÁCTICA PROFESIONAL II</td>
                        <td>3:20</td>                        
                    </tr>
                          
                    <tr>
                        <td colSpan="4" className="text-center h6">- TERCER AÑO -</td>
                    </tr>

                    <tr>
                        <td>15</td>
                        <td>6</td>
                        <td>ELECTRÓNICA Y AUTOMATIZACIÓN</td>
                        <td>2</td>                        
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>11 - 12</td>
                        <td>INSTALACIÓN DE ENERGIAS RENOVABLES</td>
                        <td>3:20</td>                        
                    </tr>   
                    <tr>
                        <td>17</td>
                        <td>-</td>
                        <td>NORMAS Y LEGISLACIÓN DE ENERGÍAS RENOVABLES</td>
                        <td>2</td>                        
                    </tr>   
                    <tr>
                        <td>18</td>
                        <td>3</td>
                        <td>INFORMÁTICA APLICADA</td>
                        <td>2</td>                        
                    </tr>   
                    <tr>
                        <td>19</td>
                        <td>6 - 7</td>
                        <td>DESARROLLO, GESTIÓN Y EVALUACIÓN DE PROYECTOS DE ENERGIAS RENOVABLES</td>
                        <td>2</td>                        
                    </tr>   
                    <tr>
                        <td>20</td>
                        <td>1 - 2 - 4</td>
                        <td>SEGURIDAD, HIGIENE Y MEDIO AMBIENTE</td>
                        <td>2</td>                        
                    </tr>   
                    <tr>
                        <td>21</td>
                        <td>14</td>
                        <td>PRÁCTICA PROFESIONAL III</td>
                        <td>3:20</td>                        
                    </tr>  
                </tbody>
            </Table>

            <h5>Total Carga Horaria: 2496 HORAS CÁTEDRA (1664 HORAS RELOJ)</h5>

        </div>
    )
}

export default CarreraEnergias

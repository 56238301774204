import React from 'react';
import {
    Link    
  } from "react-router-dom";

const OfertaEducativa = props => {
    const{carrera} = props;

    return (
        <>
        
            <h5 style={{color: '#003566'}}>{carrera.nombre}</h5>
            <p>{carrera.descripcion}</p>
            <div className="d-flex justify-content-end">                
                <Link size="sm" className="btn btn-sm btn-outline-primary" to={carrera.ruta}>Detalle</Link>
            </div>
            <hr/>
        </>
    )
}

export default OfertaEducativa

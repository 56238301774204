import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col, Table } from 'react-bootstrap';

const CarreraDesarrolloSoftware = () => {
    return (
        <div>
            
            <Encabezado titulo={"TÉCNICO SUPERIOR EN BROMATOLOGÍA - Sedes Macachín y Santa Isabel"} />

            <h4 className="text-center">Plan de Estudios: (RES. MINISTERIAL N° 1308/17) – PLAN 2018</h4>

            <Table striped bordered size="sm">
                <thead>
                    <tr>
                    <th>Código</th>
                    <th>Correlativa</th>
                    <th>Materia</th>
                    <th>H.R.</th>
                    <th>DUR.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="5" className="text-center h6">- PRIMER AÑO -</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>-</td>
                        <td>INGLES</td>
                        <td>2</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>02</td>
                        <td>-</td>
                        <td>TECNOLOGIA DE LA INFORMACION Y LA COMUNICACIÓN </td>
                        <td>3</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>-</td>
                        <td>MATEMATICA Y ESTADISTICA</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>04</td>
                        <td>-</td>
                        <td>ORGANIZACIÓN Y SEGURIDAD DEL LABORATORIO</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>-</td>
                        <td>QUIMICA</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>06</td>
                        <td>-</td>
                        <td>FISICA</td>
                        <td>2</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>07</td>
                        <td>-</td>
                        <td>MICROBILOIOGIA GENERAL</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>08</td>
                        <td>-</td>
                        <td>LABORATORIO DE BROMATOLOGIA I</td>
                        <td>4</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="text-center h6">- SEGUNDO AÑO -</td>
                    </tr>
                    <tr>
                        <td>09</td>
                        <td>5</td>
                        <td>QUIMICA ANALITICA</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>5</td>
                        <td>QUIMICA DE LOS ALIMENTOS</td>
                        <td>3</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>-</td>
                        <td>ORGANIZACIÓN Y GESTION DE EMPRESAS ALIMENTICIAS</td>
                        <td>2</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>4</td>
                        <td>NORMATIVA DE GESTION DE CALIDAD DE ALIMENTOS</td>
                        <td>2</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>5-7</td>
                        <td>BROMATOLOGIA I</td>
                        <td>4</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>-</td>
                        <td>LEGISLACION ALIMENTARIA Y SALUD PUBLICA</td>
                        <td>3</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>4-7-8</td>
                        <td>LABORATORIO DE BROMATOLOGIA II</td>
                        <td>4</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="text-center h6">- TERCER AÑO -</td>
                    </tr>

                    <tr>
                        <td>16</td>
                        <td>13</td>
                        <td>BROMATOLOGIA II</td>
                        <td>4</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>14</td>
                        <td>ANALISIS DE ALIMENTOS</td>
                        <td>4</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>10</td>
                        <td>FUNCIONES Y CARACTERISTICAS DE LOS ALIMENTOS</td>
                        <td>3</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>7-14</td>
                        <td>MICROBIOLOGIA DE LOS ALIMENTOS</td>
                        <td>4</td>
                        <td>A</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>12-14</td>
                        <td>TOXICOLOGIA ALIMENTARIA</td>
                        <td>3</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>12</td>
                        <td>HIGIENE, SEGURIDAD Y MEDIO AMBIENTE</td>
                        <td>2</td>
                        <td>C</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>15</td>
                        <td>LABORATORIO DE BROMATOLOGIA III</td>
                        <td>6</td>
                        <td>A</td>
                    </tr>
                    
                </tbody>
            </Table>
            <h5>Total Carga Horaria: 2712 HORAS CÁTEDRA (1808 HORAS RELOJ)</h5>

        </div>
    )
}

export default CarreraDesarrolloSoftware

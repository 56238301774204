import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import {
    Link    
  } from "react-router-dom";
import Encabezado from '../components/Encabezado';
import sede_santa_rosa from '../img/frente_ites.jpg'
import sede_toay from '../img/sede_toay.jpg';

const InstitucionalPage = () => {
    return (
        <div>
            <Encabezado titulo={"Nuestra Institución"} />
            <section>
                
                    <Row>
                        <Col sm={3}>

                        <div class="list-group list-group-flush">                            

                            <Link className="btn btn-outline-primary mb-2" to="/">Volver</Link>               
                            
                                                
                        </div>

                        </Col>
                        <Col sm={9}>
                            <h4>Acerca del ITES</h4>
                            <p>
                            El <b>Instituto Tecnológico de Educación Superior (ITES)</b>, surge mediante el   Decreto N٥ 1.113/02 del Poder Ejecutivo de la Provincia de La Pampa, a partir de la reconversión del Centro Educativo de Nivel Terciario – C.E.N.T. Nº 1 – en el marco de los arts. 21 y sgtes. de la Ley Provincial de Educación Nº 1.862 y art. 15 de la Ley Nacional de Educación Superior Nº 24.521, dependiendo de la Dirección General de Educación Polimodal y Superior de la Subsecretaría de Educación del  Ministerio de Cultura y Educación.
Inicia las actividades a partir del Ciclo Lectivo 2002 con la implementación de la carrera <b>Tecnicatura Superior en Administración y Gestión</b> con especialidad en “Administración de Empresas” y en “Administración Pública”. </p>
<p>El Instituto tiene su sede central en el Colegio Capitán General Don José de San Martín, con domicilio en calle General Pico Nº 51 de la ciudad de Santa Rosa (La Pampa), accediéndose al mismo por la entrada existente en la intersección de las  calles General Pico y Sarmiento de la Ciudad de Santa Rosa y  siendo el horario de dictado de clases de 20:00 a 23:35 horas. Se acompañan fotografías de puerta de acceso, y se detaca que nuestra institución es el único instituto terciario oficial de formación técnica del lugar y por lo tanto, libre y gratuito.

                            </p>
                            
                            <Image src={sede_santa_rosa} alt="Edificio Sede Central Santa Rosa" fluid className="my-3"/>
                            <p>
                            Para la elaboración de la currícula de las carreras se ha pensado en la formación y capacitación profesional para desempeñarse con solvencia en áreas ocupacionales determinadas que exigen el dominio de competencias tecnológicas y profesionales específicas relacionadas con la administración y gestión. El conocimiento y aprendizaje de las modernas teorías de gestión serán el componente de políticas activas de empleos orientadas a promover la inserción laboral y social de grupos con necesidades específicas.  Los conocimientos y habilidades profesionales  permitirán iniciarse dentro de las áreas de Asesoramiento Técnico, Operativos, de Planificación, de Recursos Humanos y en el área de Investigación sobre los problemas que se plantean en la Administración y Gestión.
                            </p>
                            
                            <Image src={sede_toay} alt="Edificio Sede Toay" fluid className="my-3"/>                            
                            
                        </Col>
                    </Row>
                    

                    
                
            </section>
        </div>

    )
}

export default InstitucionalPage

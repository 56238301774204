import React from 'react'

const Encabezado = ({titulo}) => {
    return (
        <div className="flex" style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#042144', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, marginTop: 10, marginBottom: 10}}>
            <h3 style={{color: '#fff'}}>ITES | {titulo}</h3>
        </div>
    )
}
 
export default Encabezado

import React from 'react';
import Encabezado from '../components/Encabezado';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';
import {
    Link
  } from "react-router-dom";
  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';

import foto_desarrollo from '../img/carreras_desarrollo.png';
import foto_administracion from '../img/carreras_empresas.png';
import foto_bromatologia from '../img/carreras_bromatologia.png';
import foto_energias from '../img/carreras_energias.png';
import foto_contable from '../img/carreras_contable.png';

const CarrerasPage = () => {
    return (
        <div>
            <Encabezado titulo={"Oferta Académica"} />
            <section>
                
                    <Row>
                        <Col sm={3}>

                        <div className="d-grid gap-2">
                            <Link className="btn btn-outline-primary" to="/">Volver</Link>                                                     
                        </div>

                        </Col>
                        <Col sm={9}>
                            <Row className='mb-3'>
                                <Col>
                                    <Card >
                                    <Card.Img variant="top" src={foto_desarrollo} />
                                    <Card.Body>
                                        <Card.Title>
                                            <FontAwesomeIcon icon={faUserGraduate} color='#0d6efd' />
                                            <Link to="/carrera_desarrollo" style={{textDecoration: 'none'}}> Técnico Superior en Desarrollo de Software</Link>                                            
                                        </Card.Title>
                                        <Card.Text>
                                        RESOLUCIÓN DEL MINISTERIO DE EDUCACION DE LA PROVINCIA DE LA PAMPA Nº 1892/14
                                        </Card.Text>
                                        <Link to="/carrera_desarrollo" className="btn btn-outline-primary btn-sm">PLAN DE ESTUDIOS</Link>
                                    </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                    <Card.Img variant="top" src={foto_administracion} />
                                    <Card.Body>
                                        <Card.Title>                 
                                            <FontAwesomeIcon icon={faUserGraduate} color='#0d6efd' />                           
                                            <Link to="/carrera_administracion" style={{textDecoration: 'none'}}> Técnico Superior en Administración y Gestión de Empresas</Link>  
                                        </Card.Title>
                                        <Card.Text>
                                        DECRETO DE LA PROVINCIA DE LA PAMPA Nº 1071/12
                                        </Card.Text>
                                        <Link to="/carrera_administracion" className="btn btn-outline-primary btn-sm">PLAN DE ESTUDIOS</Link>
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                    <Card.Img variant="top" src={foto_bromatologia} />
                                    <Card.Body>
                                        <Card.Title>  
                                            <FontAwesomeIcon icon={faUserGraduate} color='#0d6efd' />                                          
                                            <Link to="/carrera_bromatologia" style={{textDecoration: 'none'}}> Técnico Superior en Bromatología</Link>  
                                        </Card.Title>
                                            <Card.Text>
                                            RESOLUCIÓN DEL MINISTERIO DE EDUCACION DE LA PROVINCIA DE LA PAMPA N° 1308/17
                                            </Card.Text>
                                            <Link to="/carrera_bromatologia" className="btn btn-outline-primary btn-sm">PLAN DE ESTUDIOS</Link>
                                        </Card.Body>
                                        </Card>
                                </Col>

                                <Col>
                                    <Card>
                                    <Card.Img variant="top" src={foto_energias} />
                                    <Card.Body>
                                        <Card.Title>                   
                                            <FontAwesomeIcon icon={faUserGraduate} color='#0d6efd' />                         
                                            <Link to="/carrera_energias" style={{textDecoration: 'none'}}> Técnico Superior en Gestión de Energías Renovables</Link>  
                                        </Card.Title>
                                            <Card.Text>
                                            RESOLUCIÓN DEL MINISTERIO DE EDUCACION DE LA PROVINCIA DE LA PAMPA N° 0122/21
                                            </Card.Text>
                                            <Link to="/carrera_energias" className="btn btn-outline-primary btn-sm">PLAN DE ESTUDIOS</Link>
                                        </Card.Body>
                                        </Card>
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <Card>
                                    <Card.Img variant="top" src={foto_contable} />
                                    <Card.Body>
                                        <Card.Title>  
                                            <FontAwesomeIcon icon={faUserGraduate} color='#0d6efd' />                                          
                                            <Link to="/carrera_contable" style={{textDecoration: 'none'}}> Técnico Superior en Administración y Gestión Contable</Link>  
                                        </Card.Title>
                                            <Card.Text>
                                            RESOLUCIÓN DEL MINISTERIO DE EDUCACION DE LA PROVINCIA DE LA PAMPA N° 271/22
                                            </Card.Text>
                                            <Link to="/carrera_contable" className="btn btn-outline-primary btn-sm">PLAN DE ESTUDIOS</Link>
                                        </Card.Body>
                                        </Card>
                                </Col>

                                <Col>
                                    
                                </Col>

                            </Row>
                        
                        </Col>
                    </Row>
                </section>
        </div>
    )
}

export default CarrerasPage

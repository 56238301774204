import logo from './img/logo_ites.jpg';

// import Contenido from './components/Contenido';
// import Promo from './components/Promo';
// import {Container} from 'react-bootstrap';
// import Carrousel from './components/Carrousel';

import AppRouter from './routes/AppRouter';

function App() {
  return (
    
         
      <AppRouter />
    
    
    
  );
}

export default App;

import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../img/logo_ites.jpg';
import {
    Link,
    NavLink,
    Router
  } from "react-router-dom";

const BarraSuperior = () => {
    return (

        <Navbar collapseOnSelect expand="lg" variant="light"  style={{color: '#FFF', height: 100}}>
            <Container>
            <Navbar.Brand as={Link} to="/">
                <img
                    src={logo}                    
                    className="d-inline-block align-top"
                    alt="ITES logo"
                    height="100px"
                />
            </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        
                    </Nav>
                    <Nav>
                        <Nav.Link as={Link} to="/" style={{color:'#0D47A1', fontWeight: '600'}}>INICIO</Nav.Link>
                        <Nav.Link as={Link} to="/institucional" style={{color:'#0D47A1', fontWeight: '600'}}>INSTITUCIONAL</Nav.Link>
                        <Nav.Link as={Link} to="/carreras" style={{color:'#0D47A1', fontWeight: '600'}}>CARRERAS</Nav.Link>  
                        <Nav.Link as={Link} to="/politica_privacidad" style={{color:'#0D47A1', fontWeight: '600'}}>POLITICA PRIVACIDAD</Nav.Link>                       
                        {/* <Nav.Link href="!#" style={{color:'#0D47A1', fontWeight: '600'}}>NOTICIAS</Nav.Link>                         */}
                        <Nav.Link href="https://classroom.google.com/?emr=0s" target="blank" rel="noopener noreferrer" style={{color:'#0D47A1', fontWeight: '600'}}>CAMPUS VIRTUAL</Nav.Link>
                        <Nav.Link href="https://autogestion.ites.edu.ar/" target="blank" rel="noopener noreferrer" style={{color:'#0D47A1', fontWeight: '600'}}>AUTOGESTION</Nav.Link>
        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default BarraSuperior;

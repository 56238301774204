import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import index_1 from '../img/carreras_gratuitas.png';
import index_2 from '../img/carreras_desarrollo.png';
import index_3 from '../img/carreras_contable.png';
import index_4 from '../img/carreras_bromatologia.png';
import index_5 from '../img/carreras_energias.png';

const Carrousel = () => {
  return (
    <Carousel controls={false}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={index_1}
          alt="First slide"
        />
        <Carousel.Caption>          
          <p>.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={index_2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <p>.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={index_3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <p>.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={index_4}
          alt="Third slide"
        />
        <Carousel.Caption>
          <p>.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={index_5}
          alt="Third slide"
        />
        <Carousel.Caption>
          <p>.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default Carrousel
